import React from "react";
import "./ServicePage.css";
import { ServiceData } from "./ServiceData";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const ServicePage = () => {
  return (
    <>
      <div className="service" id="service">
        {/* <h3>We specialize in legal Basements</h3> */}
        <div className="service-info">
          {/* <Fade left>
            <div className="top-wrapper">
              <Service />
            </div>
          </Fade> */}
          <div className="bottom-wrapper">
            <h1>Services</h1>
            <Fade bottom>
              <div className="box-wrapper">
                {ServiceData.map((service, index) => (
                  <div key={index} className="box">
                    <figure>
                      <div className="overlay">
                        <h2>{service.btn}</h2>
                      </div>
                      <img src={service.img} alt="" />
                    </figure>
                    <div className="content">
                      <Link className="btn">{service.btn}</Link>
                    </div>
                  </div>
                ))}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
