import architectualDrawings from "../../assets/Services/architectualDrawings.jpg";
import basement from "../../assets/Services/basement.jpg";
import bathroomR from "../../assets/Services/bathroomR.jpg";
import flooring from "../../assets/Services/flooring.jpg";
import paint from "../../assets/Services/paint.jpg";
import tilling from "../../assets/Services/tillingImg.jpg";

export const ServiceData = [
  {
    img: architectualDrawings,

    btn: "Architectural Drawings",
  },
  {
    img: basement,

    btn: "Legal Basement",
  },
  {
    img: bathroomR,

    btn: "Bathroom Remodeling",
  },

  {
    img: flooring,

    btn: "Flooring",
  },
  {
    img: tilling,

    btn: "Tilling",
  },
  {
    img: paint,

    btn: "Paint",
  },
];
