import React, { useEffect, useState } from "react";
import "./ImageGallary.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Fade from "react-reveal/Fade";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination } from "swiper/modules";
import { gallaryData } from "./GallaryData";
const ImageGallary = () => {
  const [slidesPerView, setSlidesPerView] = useState(1); // Initialize slidesPerView with 1

  useEffect(() => {
    const handleResize = () => {
      // Check the window width and set slidesPerView accordingly
      if (window.innerWidth >= 650) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(1);
      }
    };

    // Initial call to set the correct slidesPerView
    handleResize();

    // Add a resize event listener to update slidesPerView when the window size changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="wrapper" id="gallary">
      <h1>Our Gallery </h1>
      <h3>Look at our Amazing images gallery</h3>
      <Fade right>
        <div className="our-gallary">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            effect="fade"
            speed={500}
            className="mySwiper"
          >
            <ul className="carousel">
              {gallaryData.map((data) => (
                <SwiperSlide>
                  <li className="card">
                    <div className="swiper-slide">
                      <figure>
                        <img src={data.img} alt="img" draggable="false" />
                      </figure>
                    </div>
                  </li>
                </SwiperSlide>
              ))}
            </ul>
          </Swiper>
        </div>
      </Fade>
    </div>
  );
};

export default ImageGallary;
