import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.jpg";
import "./Header.css";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <>
      <nav>
        <div className="top-header">
          <h2>PB X BUILDERS</h2>
          <div className="left-side">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                />
              </svg>
            </div>
            <a href="tel:+16043680751">Call Us +1 604-368-0751</a>
          </div>
        </div>
        <div className="navbar">
          <ScrollLink to="home" className="logo">
            <img src={logo} alt="" />
          </ScrollLink>

          {/* Desktop Navigation */}
          <div className="desktop-nav">
            <ul className="nav-links">
              <li>
                <ScrollLink to="home">Home</ScrollLink>
              </li>
              <li>
                <ScrollLink to="gallary">Gallery</ScrollLink>
              </li>
              <li>
                <ScrollLink to="about">About</ScrollLink>
              </li>
              <li>
                <ScrollLink to="service">Service</ScrollLink>
              </li>
              <li>
                <ScrollLink to="contact">Contact Us</ScrollLink>
              </li>
            </ul>
          </div>

          {/* Mobile Navigation */}
          <div className="mobile-nav">
            <div className="nav-toggle">
              <div
                className="hamb"
                onClick={() => setToggleDropDown((prev) => !prev)}
              >
                {/* hello */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>

              {toggleDropDown && (
                <div className="dropdown">
                  <ScrollLink
                    to="home"
                    onClick={() => setToggleDropDown(false)}
                  >
                    Home
                  </ScrollLink>
                  <ScrollLink
                    to="gallary"
                    onClick={() => setToggleDropDown(false)}
                  >
                    Gallery
                  </ScrollLink>
                  <ScrollLink
                    to="about"
                    onClick={() => setToggleDropDown(false)}
                  >
                    About
                  </ScrollLink>
                  <ScrollLink
                    to="service"
                    onClick={() => setToggleDropDown(false)}
                  >
                    Service
                  </ScrollLink>
                  <ScrollLink
                    to="contact"
                    onClick={() => setToggleDropDown(false)}
                  >
                    Contact Us
                  </ScrollLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
